<template>
  <div class="view-dialog">
    <el-form
      class="view-dialog_content"
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
    >
      <el-form-item class="view-name content-bg" :label="$t('devices.esl.viewName')" prop="viewName">
        <!-- <span>{{ $t("devices.esl.viewName") }}</span> -->
        <el-input v-model="ruleForm.viewName"></el-input>
        <el-checkbox
          v-model="ruleForm.defaultType"
          @change="handelDefaultType"
          >{{ $t("devices.esl.default") }}</el-checkbox
        >
      </el-form-item>
      <el-form-item class="view-visible content-bg" :label="$t('devices.esl.visibleRRange')" prop="openType">
        <!-- <span>{{ $t("devices.esl.visibleRRange") }}</span> -->
        <div>
          <el-radio
            :disabled="item.disabled"
            v-for="(item, index) in radioList"
            :key="index"
            v-model="ruleForm.openType"
            :label="item.id"
            @change="changeRadio"
            >{{ item.title }}</el-radio
          >
        </div>
      </el-form-item>
      <el-form-item class="filter-visible" prop="">
        <div>{{ $t("devices.esl.filter") }}</div>
        <div
          class="con"
          v-for="(showItem, indexItem) in viewQueryCondition"
          :key="indexItem"
        >
          <!-- left -->
          <el-select
            v-model="showItem.columnName"
            ref="select-left"
            class="select-left"
            @change="changeFieldLeft(showItem, indexItem)"
            placeholder=""
          >
            <el-option
              v-for="(item, l) in createBeforeColumnList"
              :key="l"
              :label="item.columnName"
              :value="item.fieldName"
            >
            </el-option>
          </el-select>

          <!-- center -->
          <el-select
            v-model="showItem.condtion"
            ref="select-center"
            class="select-center"
            @change="changeCharacter(showItem, indexItem)"
            placeholder=""
          >
            <el-option
              v-for="item in showItem.characterList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
              {{ item.name }}
            </el-option>
          </el-select>

          <!-- right -->
          <!-- text，number类型 -->
          <el-input
            class="select-right"
            v-if="
              showItem.fieldType === 'text' || showItem.fieldType === 'number'
            "
            v-model="showItem.fieldValue"
            @input="getFilterValue(showItem.fieldValue, indexItem)"
            placeholder=""
          />
          <!-- 时间 -->
          <el-date-picker
            class="select-right"
            v-else-if="showItem.fieldType === 'date'"
            v-model="showItem.fieldValue"
            @input="getFilterValue(showItem.fieldValue, indexItem)"
            type="date"
            placement="bottom-start"
            value-format="yyyy-MM-dd"
            placeholder=""
          >
          </el-date-picker>
          <!-- 下拉多选 -->
          <el-select
            v-else
            class="select-right"
            v-model="showItem.checkboxFieldValue"
            multiple
            @change="getFilterValue(showItem.checkboxFieldValue, indexItem)"
            placeholder=""
          >
            <el-option
              v-for="(item, l) in showItem.checkBoxList"
              :key="l"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-button
            class="add-btn"
            v-if="indexItem === 0"
            @click="handelAdd"
            >{{ $t("devices.esl.add") }}</el-button
          >
          <i
            v-else
            class="delect el-icon-close"
            @click="handelDelete(indexItem)"
          />
        </div>
      </el-form-item>
      <el-form-item class="select-column" :label="$t('devices.esl.selectColumn')" prop="checkedField">
        <!-- <span>{{ $t("devices.esl.selectColumn") }}</span> -->
        <el-scrollbar style="height: 100%">
          <el-checkbox-group
            v-model="ruleForm.checkedField"
            @change="handleCheckedColumnType"
          >
            <el-checkbox
              v-for="(item, i) in createBeforeColumnList"
              :key="i"
              :label="item"
              >{{ item.columnName }}</el-checkbox
            >
          </el-checkbox-group>
        </el-scrollbar>
      </el-form-item>
    </el-form>

    <!-- <confirm-dialog
      :dialogVisible="isVisibleConfirmDialog"
      @handleClose="handelConfirmDialog"
      :confirmObj="confirmObj"
    >
    </confirm-dialog> -->

     <el-dialog
      :title="confirmObj.type"
      :visible.sync="isVisibleConfirmDialog"
      :before-close="handelConfirmDialog"
      :append-to-body="true"
      class="confirm-dialog"
    >
      <div class="cont">
        <!-- <span class="icon"><i class="el-icon-warning-outline"></i></span> -->
        <span class="text">{{confirmObj.content}}</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <span class="cancel" @click="handelConfirmDialog('cancel')">{{confirmObj.cancelText}}</span>
        <span class="confirm" v-loading="confirmObj.loading ? confirmObj.loading : false" @click="handelConfirmDialog('confirm')">{{confirmObj.confirmText}}</span>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { createBefore, getViewDetail } from "@/libs/api/esls";

import { characterTypeList } from "@/libs/enum";
import ConfirmDialog from '@/components/confirm-dialog/index.vue'
export default {
  name: "SelectColumn",
  components:{
    ConfirmDialog
  },
  props: {
    columnList: {
      type: Array,
      require: true,
      default: () => [],
    },
    viewId: {
      type: String,
      require: true,
      default: "",
    },
    clickViewType: {
      type: String,
      require: true,
      default: "",
    },
  },
  data() {
    return {
      characterTypeList,
      defaultType: false,
      ruleForm: {
        viewName: "",
        openType: "",
        defaultType: false,
        checkedField: [],

        checkFieldList: [], //选择的column字段
      },
      rules: {
        viewName: [
          {
            required: true,
            message: this.$t("devices.esl.enterViewName"),
            trigger: "blur",
          },
        ],
        openType: [
          {
            required: true,
            message: this.$t("devices.esl.selectOpentype"),
            trigger: ["blur", "change"],
          },
        ],
        checkedField: [
          {
            required: true,
            message: this.$t("devices.esl.selectedColumn"),
            trigger: ["blur", "change"],
          },
        ],
      },
      // radioList: [
      //   { id: 2, title: this.$t("ThisStore"), disabled: false },
      //   { id: 0, title: this.$t("AllStore"), disabled: false },
      //   { id: 1, title: this.$t("ZimenStore"), disabled: false },
      // ],
      radioList:[
        { id: 2, title: this.$t('itemsScenarios.ViewDialog.ThisUser'), disabled: false},
        { id: 0, title: this.$t('itemsScenarios.ViewDialog.ThisStore'), disabled: false},
        { id: 1, title: this.$t('itemsScenarios.ViewDialog.AllStore'), disabled: false}
      ],

      viewQueryCondition: [{}],
      createBeforeColumnList: [], //  创建视图前，视图字段列表

      selectMap: {},
      delectfilterConditionVisible: false,
      delectIndex: null, // 删除filter   index

      isVisibleConfirmDialog: false,
      currentConfirmType: "",  
      confirmObj: {
        type: this.$t("devices.tips"),
        cancelText: this.$t("devices.cancel"),
        confirmText: this.$t("devices.sure"),
        content: this.$t("devices.esl.deleteViewTips")
      },
    };
  },
  methods: {
    handelAdd() {
      if (this.viewQueryCondition.length === 5) {
        this.$message({
          message: this.$t("devices.esl.selectUpToTtems"),
          type: "warning",
        });
        return;
      }
      this.viewQueryCondition.push({
        fieldId: "",
        fieldType: "",
        fieldName: "",
        columnName: "",
        condtion: "",
        fieldValue: "",
        checkboxFieldValue: [],
        characterList: [],
      });
    },
    handelDelete(index) {
      // this.viewQueryCondition.splice(index, 1);
      this.delectIndex = index;
      this.confirmObj.content = this.$t("devices.esl.deleteViewTips");
      this.currentConfirmType = "deleteFilterCondition";
      this.isVisibleConfirmDialog = true;
    },
    handelDefaultType(val) {
      if (val) {
        this.radioList[1]["disabled"] = true;
        this.radioList[2]["disabled"] = true;
      } else {
        this.radioList.forEach((item) => {
          item["disabled"] = false;
        });
      }
    },
    changeRadio() {},
    handleCheckedColumnType(val) {
      let checkFieldList = [];
      for (let i = 0; i < val.length; i++) {
        checkFieldList.push({
          fieldName: val[i].fieldName,
          fieldType: val[i].type,
          columnName: val[i].fieldName,
          sortFlag: val[i].sortFlag,
          sortField: val[i].fieldName,
        });
      }
      this.ruleForm.checkFieldList = checkFieldList;
    },
    // filter 第一项选择
    changeFieldLeft(item, index) {
      const { createBeforeColumnList, characterTypeList, selectMap } = this;
      this.viewQueryCondition[index]["condtion"] = "";
      this.viewQueryCondition[index]["fieldValue"] = "";
      this.viewQueryCondition[index]["checkboxFieldValue"] = [];
      createBeforeColumnList.forEach((itemVal) => {
        if (itemVal.fieldName === item.columnName) {
          this.viewQueryCondition[index]["fieldType"] = itemVal.type;
          this.viewQueryCondition[index]["characterList"] =
            characterTypeList[itemVal.type];
          this.viewQueryCondition[index]["fieldId"] = itemVal.fieldId;
          if (itemVal.type === "checkbox") {
            for (let k in selectMap) {
              if (k === itemVal.listKey) {
                this.viewQueryCondition[index]["checkBoxList"] = selectMap[k];
                break;
              } else {
                this.viewQueryCondition[index]["checkBoxList"] = [];
              }
            }
          }
        }
      });
    },
    changeCharacter(item, index) {
      this.viewQueryCondition[index]["condtion"] = item.condtion;
      this.$forceUpdate(); //视图不更新
    },
    // filter 第三项选择
    getFilterValue(val, index) {
      this.viewQueryCondition[index]["fieldValue"] = val;
      this.viewQueryCondition[index]["checkboxFieldValue"] = val;
      this.$forceUpdate(); //视图不更新
    },
    //获取视图选择数据list下拉框
    async getCreateBeforeViewList() {
      try {
        const { data } = await createBefore({ viewType: "1" });
        if (data.state === true) {
          let dataNew = data.data || {};
          let { viewList, selectMap } = dataNew;

          if (viewList.viewField && typeof viewList.viewField === "string") {
            viewList.viewField = JSON.parse(viewList.viewField);
          }

          this.createBeforeColumnList = viewList.viewField || [];
          this.selectMap = selectMap;
        } else {
          this.$message.error(data.message);
        }
      } catch (err) {
        const { message } = err;
        this.$message.error(message);
      }
    },
    // 获取视图详情
    async getViewDetail() {
      try {
        const { data } = await getViewDetail({
          id: this.viewId,
          scenarioId: "",
        });
        if (data.state === true) {
          let dataNew = data.data || {};
          let {
            defaultType,
            openType,
            viewCondition,
            viewListFields,
            viewName,
          } = dataNew;
          const { createBeforeColumnList, clickViewType, selectMap } = this;
          this.ruleForm.defaultType = defaultType == -1 ? true : false;
          this.ruleForm.viewName =
            clickViewType === "copy" ? `${viewName}(${this.$t("devices.esl.copy")})` : viewName;
          this.ruleForm.openType = openType;

          if (this.ruleForm.defaultType) {
            this.radioList[1]["disabled"] = true;
            this.radioList[2]["disabled"] = true;
          } else {
            this.radioList.forEach((item) => {
              item["disabled"] = false;
            });
          }

          if (viewCondition && typeof viewCondition === "string") {
            viewCondition = JSON.parse(viewCondition);
          }
          if (viewListFields && typeof viewListFields === "string") {
            viewListFields = JSON.parse(viewListFields);
          }
          this.ruleForm.checkFieldList = viewListFields || [];
          this.viewQueryCondition =
            viewCondition && viewCondition.length ? viewCondition : [{}];

          createBeforeColumnList.forEach((item) => {
            this.viewQueryCondition.forEach((itemCondition, index) => {
              itemCondition.characterList =
                this.characterTypeList[itemCondition.fieldType];
              //viewQueryCondition checkbox处理
              if (itemCondition.fieldType === "checkbox") {
                // 处理checkout值的回显
                let checkboxFieldValue = [];
                if (typeof itemCondition.fieldValue === "string") {
                  itemCondition.fieldValue =
                    itemCondition.fieldValue.split(",");
                  checkboxFieldValue = itemCondition.fieldValue.map(Number); //后端返回的字符串转数字
                  itemCondition.checkboxFieldValue = checkboxFieldValue;
                }
                // 根据listKey 查找checkBoxList
                if (itemCondition.fieldName === item.fieldName) {
                  for (let k in selectMap) {
                    if (k === item.listKey) {
                      itemCondition.checkBoxList = selectMap[k];
                      break;
                    }
                  }
                }
              }
            });

            // column选中回显赋值
            viewListFields.forEach((itemView) => {
              if (
                itemView.fieldName === item.fieldName &&
                (itemView.type === item.type ||
                  itemView.fieldType === item.type)
              ) {
                this.ruleForm.checkedField.push(item);
              }
            });
          });
        } else {
          this.$message.error(data.message);
        }
      } catch (err) {
        const { message } = err;
        this.$message.error(message);
      }
    },

    async initData() {
      await this.getCreateBeforeViewList();
      const _this = this;
      if (this.clickViewType === "add") {
        //新增
        this.viewQueryCondition = [
          {
            fieldId: "",
            fieldType: "",
            fieldName: "",
            columnName: "",
            condtion: "",
            fieldValue: "",
            checkboxFieldValue: [],
            characterList: [],

          },
        ];
        this.ruleForm = {
          viewName: "",
          openType: "",
          defaultType: false,
          checkedField: [],

          checkFieldList: [],
        };
        setTimeout(() => {
          _this.$refs.ruleForm.resetFields();
        }, 30);
      } else if (this.viewId) {
        await this.getViewDetail();
      }
    },

    handelConfirmDialog(val) {
      if (val === "confirm") {
        const { currentConfirmType } = this;
        
        if (currentConfirmType === "deleteFilterCondition") {
          this.viewQueryCondition.splice(this.delectIndex, 1);
          this.$message.success(this.$t("devices.deleteSuccessTips"));
          this.isVisibleConfirmDialog = false;
        }
      } else {
        this.isVisibleConfirmDialog = false;
      }
    },
  },
  async created() {
    this.initData();
  },
};
</script>
<style lang="scss" scoped>
.view-dialog {
  &_content {
    .content-bg {
      background: #f9f9f9;
      border-radius: 6px;
    }
    ::v-deep .el-form-item__label{
      //display: block !important;
      text-align: left !important;
      float: none !important;
      line-height: 1;
      // margin-bottom: 20px;
      height: 40px;
      line-height: 40px;
      margin-bottom: 0 !important;
    }
    .view-name {
      height: 120px;
      padding: 10px 24px 0 24px;
      margin-bottom: 10px;
      span {
        display: block;
      }
      ::v-deep .el-input {
        margin-right: 10px;
        width: 360px;
        height: 40px;
        margin-right: 10px;
      }
    }
    .view-visible {
      height: 120px;
      padding: 16px 24px 0 24px;
      margin-bottom: 10px;
      span {
        display: block;
      }
    }

    .filter-visible ::v-deep {
      .con {
        margin-bottom: 10px;
        .add-btn {
          background: #35b871 !important;
          box-shadow: 0px 1px 15px 0px rgba(53, 184, 113, 0.55);
          color: #ffffff;
          width: 90px;
          border-radius: 6px;
        }
      }
      background: #f9f9f9;
      border-radius: 6px;
      padding: 24px;
      margin-bottom: 10px;
      .el-select.select-left {
        width: 140px;
        margin-right: 5px;
      }
      .el-select.select-center {
        width: 90px;
        margin-right: 5px;
      }
      .select-right {
        width: 140px;
        margin-right: 5px;
      }
      .text-input {
        width: 140px;
      }
      .delect.el-icon-close {
        font-size: 30px;
        vertical-align: middle;
        cursor: pointer;
        margin-left: 20px;
      }
      .el-button {
        // margin-left: 10px;
      }
    }

    .select-column {
      background: #f9f9f9;
      border-radius: 6px;
      padding: 10px 24px 20px 24px;
      margin-bottom: 10px;
      span {
        // display: block;
        margin-bottom: 17px;
      }
      ::v-deep .el-form-item__content {
        // height: 400px;
        height: 220px;
      }
      ::v-deep .el-checkbox {
        // display: block;
      }
      ::v-deep .el-checkbox-group {
        display: flex;
        flex-wrap: wrap;
        .el-checkbox {
          width: 40%;
        }
      }
    }
  }
}

.confirm-dialog ::v-deep{
  display: flex;
  justify-content: center;
  align-items: center;
  color:#35B871;
    .el-dialog__wrapper{
      display: flex;
      justify-content: center;
      align-items: center;
    }
     .el-dialog{
      height: 300px;
      border-radius: 6px;
      width: 400px;
      margin-top: 0 !important;
      margin: 0 !important;
    }
    ::v-deep .el-dialog__body{
      padding: 0 !important;
    }
    .cont{
      text-align: center;
      padding-top: 40px;
      img{
        width: 46px;
        height: 46px;
        display: block;
        margin: 0 auto;
      }
      .icon {
        display: flex;
        justify-content: center;
        color: #FF9600;
      }
      ::v-deep .el-icon-warning-outline {
        font-size: 46px;
      }
      .text{
        margin-top: 16px;
        display: inline-block;
        // color: #FF9600;
      }
    }
    .cancel, .confirm{
      cursor: pointer;
      width: 50%;
      position: absolute;
      bottom: 0;
      height: 50px;
      line-height: 50px;
      border-top: 1px solid #E6E6E6;
      vertical-align: center;

    }
    .cancel{
      border-right: 1px solid #E6E6E6;
      left: 0;
      color:#333333;
    }
    .confirm{
      right: 0;
      color: #35B871;
    }
}
</style>