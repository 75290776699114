<template>
  <div>
    <div class="communication-records_top">
      <span class="record-text">{{ $t("devices.esl.storeName") }}: </span>
      <span style="margin-right: 12px"> {{ currentItem.storeName }}</span>
      <span class="record-text">{{ $t("devices.esl.eslCode") }}: </span>
      {{ currentItem.tagIdentity }}
    </div>
    <el-table
      border
      :data="tableData"
      style="width: 100%"
      v-loading="tableLoading"
      @sort-change="handleSort"
    >
      <el-table-column
        align="center"
        width="55"
        type="index"
        :label="$t('devices.esl.No')"
        fixed="left"
      >
        <el-table-column align="center" width="55" type="index">
        </el-table-column>
      </el-table-column>
      <el-table-column
        align="center"
        min-width="180"
        prop="order_type"
        :label="$t('devices.esl.taskType')"
        sortable="custom"
      >
        <el-table-column min-width="180" align="center" prop="orderType">
          <template slot="header" slot-scope="scope">
            <el-select
              v-model="pageParams.orderType"
              placeholder=""
              size="small"
              clearable
              @change="getList"
            >
              <el-option
                v-for="(item, i) in taskTypeList"
                :key="i"
                :label="item.codeName"
                :value="item.codeId"
              />
            </el-select>
          </template>
          <span slot-scope="scope" width="300">
            {{ scope.row.taskTypeName }}
          </span>
        </el-table-column>
      </el-table-column>
      <el-table-column
        align="center"
        width="180"
        prop="update_result"
        :label="$t('devices.esl.status')"
        sortable="custom"
        min-width="160"
      >
        <el-table-column min-width="160" align="center" prop="updateResult">
          <template slot="header" slot-scope="scope">
            <el-select
              v-model="pageParams.updateResult"
              placeholder=""
              size="small"
              clearable
              @change="getList"
            >
              <el-option
                v-for="(item, i) in elsStateList"
                :key="i"
                :label="item.codeName"
                :value="item.codeId"
              />
            </el-select>
          </template>
          <span
            slot-scope="scope"
            width="300"
            class="update-state"
            :class="{
              'state-success': 
                scope.row.updateResult == ESLE_STATE_ACTIVE ||
                scope.row.updateResult == ESLE_STATE_UPDATING ||
                scope.row.updateResult == ESLE_STATE_SUCCESS,
              'state-failed': 
                scope.row.updateResult == ESLE_STATE_FAILED ||
                scope.row.updateResult == ESLE_STATE_UNACTIVE,
            }"
          >
            {{ scope.row.updateResultName }}
          </span>
        </el-table-column>
      </el-table-column>
      <el-table-column
        width="180"
        align="center"
        prop="create_time"
        :label="$t('devices.esl.updateTime')"
        sortable="custom"
      >
        <el-table-column width="300" align="center" prop="createTime">
          <template slot="header" slot-scope="scope">
            <el-date-picker
              v-model="pageParams.date"
              type="daterange"
              start-placeholder=""
              end-placeholder=""
              size="small"
              @input="handleDateChange"
              placement="bottom-start"
              style="width: 90%"
              clearable
            />
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column
        align="center"
        width="180"
        prop="goods_msg"
        :label="$t('devices.esl.itemCode')"
        sortable="custom"
        min-width="180"
      >
        <el-table-column min-width="180" align="center" prop="goodsMsg">
          <template slot="header" slot-scope="scope">
            <el-input
              v-model="pageParams.goodsMsg"
              @input="getList"
              size="small"
              placeholder=""
              clearable
            />
          </template>
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              :content="scope.row.goodsCode"
            >
              <span class="text-ellipsis" style="width: 120px"
                >{{ scope.row.goodsCode }}
              </span>
            </el-tooltip>
          </template>
        </el-table-column></el-table-column
      >
      <el-table-column
        align="center"
        min-width="180"
        prop="tid"
        :label="$t('devices.esl.taskID')"
        sortable="custom"
      >
        <el-table-column min-width="180" align="center" prop="tid">
          <template slot="header" slot-scope="scope">
            <el-input
              v-model="pageParams.tid"
              @input="getList"
              size="small"
              placeholder=""
              clearable
            />
          </template> </el-table-column
      ></el-table-column>
      <el-table-column
        align="center"
        min-width="160"
        prop="update_message"
        :label="$t('devices.esl.message')"
        sortable="custom"
      >
        <el-table-column min-width="160" align="center" prop="updateMessage">
          <template slot="header" slot-scope="scope">
            <el-input
              v-model="pageParams.updateMessage"
              @input="getList"
              size="small"
              placeholder=""
              clearable
            />
          </template> </el-table-column
      ></el-table-column>
    </el-table>
    <pagination :tableInfo="pageParams" @changePage="changePage" />
  </div>
</template>
<script>
import moment from "moment";
import { debounce } from "lodash";
import { getRecordList } from "@/libs/api/esls";
import { paginationEnum } from "@/libs/enum";
import pagination from "@/components/pagination";
import {ESLE_STATE_UNACTIVE, ESLE_STATE_ACTIVE, ESLE_STATE_UPDATING, ESLE_STATE_SUCCESS, ESLE_STATE_FAILED } from "@/libs/enum";
export default {
  components: {
    pagination,
  },
  props: {
    currentItem: {
      type: Object,
      require: true,
      default: () => {},
    },
    taskTypeList: {
      type: Array,
      require: true,
      default: () => [],
    },
    elsStateList: {
      type: Array,
      require: true,
      default: () => [],
    },
  },
  name: "ESLUpdateRecords",
  data() {
    return {
      ESLE_STATE_UNACTIVE, 
      ESLE_STATE_ACTIVE, 
      ESLE_STATE_UPDATING, 
      ESLE_STATE_SUCCESS, 
      ESLE_STATE_FAILED,
      paginationEnum,
      moment,
      pageParams: {
        current: 1,
        size: 10,
        total: 0,

        tid: "",
        updateResult: "",
        orderType: "",
        updateResult: "",
        createTimeStart: "",
        createTimeEnd: "",
        goodsMsg: "",
        updateMessage: "",
        tagRecordId: "",

        date: "",
        orders: []
      },
      tableData: [],
      tableLoading: false,
    };
  },
  methods: {
     // 表格排序
    handleSort(column, prop, order){
      console.log('表格排序',column, prop, order)
      let arr = []
      if(column.order){
        let str1= column.order === "ascending" ? "asc" : "desc";
        str1 = str1 + ':'+ column.prop;
        arr.push(str1)
      } else {
        arr = []
      }
      
      this.pageParams.orders = arr;
      this.getList()
    },
    changePage() {
      this.getList();
    },
    handleDateChange(val) {
      if (val) {
        // this.pageParams.createTimeStart = moment(val[0]).format(
        //   "YYYY-MM-DD"
        // );
        // this.pageParams.createTimeEnd = moment(val[1]).format(
        //   "YYYY-MM-DD"
        // );
        let createTimeStart = val[0];
        let createTimeEnd = val[1];
        this.pageParams.createTimeStart = createTimeStart;
        this.pageParams.createTimeEnd = createTimeEnd;
        this.pageParams.date = val;
      } else {
        this.pageParams.createTimeStart = "";
        this.pageParams.createTimeEnd = "";
        this.pageParams.date = [];
      }
      this.getList();
    },
    getList: debounce(async function () {
      const _this = this;
      const params = {
        tagId: this.currentItem.tagId,
        orderType: _this.pageParams.orderType,
        updateResult: _this.pageParams.updateResult,
        createTimeStart: _this.pageParams.createTimeStart,
        createTimeEnd: _this.pageParams.createTimeEnd,
        goodsMsg: _this.pageParams.goodsMsg,
        updateMessage: _this.pageParams.updateMessage,
        tId: _this.pageParams.tid,

        current: _this.pageParams.current,
        size: _this.pageParams.size,
        orders: _this.pageParams.orders,
      };
      _this.tableLoading = true;
      try {
        const { data } = await getRecordList(params);
        if (data.state === true) {
          let dataNew = data.data || {};
          _this.tableData = dataNew.records || [];
          _this.tableData.forEach((item) => {
            let goodsCode = JSON.parse(JSON.stringify(item.goodsMsg));
            if (item.goodsMsg && typeof goodsCode === "object") {
              goodsCode = goodsCode.join(",");
            }
            item.goodsCode = goodsCode;
          });
          _this.pageParams.total = dataNew.total || 0;
          _this.tableLoading = false;
        } else {
          this.$message.error(data.message);
          _this.tableLoading = false;
        }
      } catch (err) {
        const { message } = err;
        _this.$message.error(message);
        _this.tableLoading = false;
      }
    }, 1000),
  },
  created() {
    this.getList();
  },
};
</script>
<style lang="scss" scoped>
.communication-records_top {
  margin-bottom: 20px;
  .record-text {
    font-size: 16px;
    // color: #999999;
  }
}
.update-state {
  padding: 3px 18px;
  text-align: center;
  border-radius: 15px;
  display: inline-block;
}
.state-success {
  background: rgba(217, 250, 232, 1);
}
.state-failed {
  background: rgba(255, 224, 224, 1);
}
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>