<template>
  <el-form
    :model="ruleForm"
    :rules="rules"
    ref="ruleForm"
    label-width="30px"
    class="demo-ruleForm"
  >
    <el-row>
      <el-col :span="8">
        <el-form-item label="" prop="storeName">
          <div>{{ $t("devices.esl.storeName") }}</div>
          <el-input disabled v-model="currentItem.storeName"></el-input>
        </el-form-item>
      </el-col>

      <el-col :span="8">
        <el-form-item label="" prop="macAddress">
          <div>{{ $t("devices.esl.relatedAP") }}</div>
          <el-input disabled v-model="currentItem.macAddress"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="" prop="tagIdentity">
          <div>{{ $t("devices.esl.eslCode") }}</div>
          <el-input disabled v-model="currentItem.tagIdentity"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="" prop="coorIp">
          <div>{{ $t("devices.esl.aPIP") }}</div>
          <el-input disabled v-model="currentItem.coorIp"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label=" " prop="tagTypeName">
          <div>{{ $t("devices.esl.eSLType") }}</div>
          <el-input disabled v-model="currentItem.tagTypeName"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="  " prop="lastTaskTypeName">
          <div>{{ $t("devices.esl.taskType") }}</div>
          <el-input disabled v-model="currentItem.lastTaskTypeName"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label=" " prop="currentPage">
          <div>{{ $t("devices.esl.currentPage") }}</div>
          <el-input disabled v-model="currentItem.currentPage"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label=" " prop="ver">
          <div>{{ $t("devices.esl.firewareVersion") }}</div>
          <el-input disabled v-model="currentItem.ver"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label=" " prop="updateTime">
          <div>{{ $t("devices.esl.updateTime") }}</div>
          <el-input disabled v-model="currentItem.updateTime"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label=" " prop="temperature">
          <div>{{ $t("devices.esl.temperature") }}</div>
          <el-input disabled v-model="currentItem.temperature"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label=" " prop="tagPower">
          <div>{{ $t("devices.esl.electricity") }}</div>
          <el-input disabled v-model="currentItem.tagPowerName"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label=" " prop="tagState">
          <div>{{ $t("devices.esl.ESL Status") }}</div>
          <el-input disabled v-model="currentItem.tagStateName"></el-input>
          <!-- <el-select
            disabled
            v-model="currentItem.tagState"
            multiple
            placeholder=""
            style="width: 100%"
          >
            <el-option
              v-for="item in stateList"
              :key="item.codeId"
              :label="item.codeName"
              :value="item.codeId"
            >
            </el-option>
          </el-select> -->
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label=" " prop="nfcBarcode">
          <div>{{ $t("devices.esl.NFC") }}</div>
          <el-input disabled v-model="currentItem.nfcBarcode"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label=" " prop="connectivityName">
          <div>{{ $t("devices.esl.connectivity") }}</div>
          <el-input disabled v-model="currentItem.connectivityName"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="" prop="goodsCode">
          <div>{{ $t("devices.esl.itemCodeItemName") }}</div>
          <el-scrollbar class="goods-item">
            <div v-for="(item, i) in currentItem.goodsCode" :key="i">
              <div
                v-show="i == j"
                v-for="(itemName, j) in currentItem.goodsName"
                :key="j"
              >
                {{ item }} /{{ itemName }}
              </div>
            </div>
          </el-scrollbar>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
export default {
  props: {
    currentItem: {
      type: Object,
      require: true,
      default: () => ({}),
    },
    stateList: {
      type: Array,
      require: true,
      default: () => [],
    },
  },
  data() {
    return {
      rules: {},
      ruleForm: {
        storeName: "",
        goodsCode: "",
        eslCode: "",
        storeName: "",
        status: [1001, 1002],
      },
    };
  },
  watch: {},
  methods: {
    // async getTagDetail() {
    //   try {
    //     const { data } = await getTagDetail({
    //       tagId: this.currentItem.tagId,
    //     });
    //     if (data.state === true) {
    //       let dataNew = data.data || {};
    //     } else {
    //       this.$message.error(data.message);
    //     }
    //   } catch (err) {
    //     const { message } = err;
    //     this.$message.error(message);
    //   }
    // },
  },
  created() {
    // this.getTagDetail();
  },
};
</script>

<style lang="scss" scoped>
.goods-item {
  height: 131px;
  border: 1px solid;
  border-radius: 6px;
  background-color: #f5f7fa;
  border-color: #e4e7ed;
  cursor: not-allowed;
  padding-left: 20px;
  color: #333333;
}
</style>
