<template>
  <div class="table-content">
    <div class="communication-records_top">
      <span class="record-text">
        {{ $t("devices.records") }}: {{ pageParams.total }}
      </span>
    </div>
    <el-table
      border
      :data="tableData"
      style="width: 100%"
      @selection-change="handleSelectionChange"
      v-loading="tableLoading"
    >
      <el-table-column align="center" type="selection">
        <el-table-column
          align="center"
          width="55"
          type="selection"
          style="display: none"
        >
        </el-table-column>
      </el-table-column>
      <el-table-column
        align="center"
        width="55"
        type="index"
        :label="$t('devices.esl.No')"
      >
        <el-table-column type="index" align="center" width="55">
        </el-table-column>
      </el-table-column>
      <el-table-column
        align="center"
        width="180"
        prop="tagTypeId"
        :label="$t('devices.esl.ESL')"
        sortable
        :sort-method="sortTagTypeId"
        :show-overflow-tooltip="true"
      >
        <el-table-column align="center" prop="tagTypeId">
          <template slot="header" slot-scope="scope">
            <el-select
              v-model="pageParams.tagTypeId"
              placeholder=""
              size="small"
              clearable
              @change="getList"
            >
              <el-option
                v-for="(item, i) in allTagTypeList"
                :key="i"
                :label="item.typeName"
                :value="item.tagTypeCodeId"
              />
            </el-select>
          </template>
          <span slot-scope="scope" width="300">
            {{ scope.row.typeName }}
          </span>
        </el-table-column>
      </el-table-column>
    </el-table>
    <pagination :tableInfo="pageParams" @changePage="changePage" />
  </div>
</template>
<script>
import moment from "moment";
import { debounce } from "lodash";
import { getAllTagTypePage } from "@/libs/api/esls";
import {
  COOR_STATE_CONNECTED,
  COOR_STATE_CONNECTING,
  paginationEnum,
} from "@/libs/enum";
import pagination from "@/components/pagination";
export default {
  components: {
    pagination,
  },
  props: {
    allTagTypeList: {
      type: Array,
      require: true,
      default: () => [],
    },
  },
  name: "TransmitByEslType",
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      moment,
      COOR_STATE_CONNECTED,
      COOR_STATE_CONNECTING,
      pageParams: {
        current: 1,
        size: 10,
        total: 0,
        tagTypeId: "",
      },
      tableData: [],
      tableLoading: false,
      eslIds: [],
      paginationEnum,
    };
  },
  methods: {
    sortTagTypeId(a, b) {
      let res = 0;
      for (let i = 0; ; i++) {
        if (!a[i] || !b[i]) {
          res = a.length - b.length;
          break;
        }
        const char1 = a[i];
        const char1Type = this.getChartType(char1);
        const char2 = a[i];
        const char2Type = this.getChartType(char2);
        // 类型相同的逐个比较字符
        if (char1Type[0] === char2Type[0]) {
          if (char1 === char2) {
            continue;
          } else {
            if (char1Type[0] === "zh") {
              res = char1.localeCompare(char2);
            } else if (char1Type[0] === "en") {
              res = char1.charCodeAt(0) - char2.charCodeAt(0);
            } else {
              res = char1 - char2;
            }
            break;
          }
        } else {
          // 类型不同的，直接用返回的数字相减
          res = char1Type[1] - char2Type[1];
          break;
        }
      }
      return res;
    },
    getChartType(char) {
      // 数字（0->9）->大写字母（A->Z）->小写字母（a->z）->中文拼音（a->z）
      if (/^[\u4e00-\u9fa5]$/.test(char)) {
        return ["zh", 300];
      }
      if (/^[a-zA-Z]$/.test(char)) {
        return ["en", 200];
      }
      if (/^[0-9]$/.test(char)) {
        return ["number", 100];
      }
      return ["others", 999];
    },
    changePage() {
      this.getList();
    },
    getList: debounce(async function () {
      const _this = this;
      const params = {
        merchantId: this.userData.merchantId,
        current: _this.pageParams.current,
        size: _this.pageParams.size,
        orders: [],
        tagTypeId: _this.pageParams.tagTypeId,
      };
      _this.tableLoading = true;
      try {
        const { data } = await getAllTagTypePage(params);
        if (data.state === true) {
          let dataNew = data.data || {};
          _this.tableData = dataNew.records || [];
          _this.pageParams.total = dataNew.total || 0;
          _this.tableLoading = false;
        } else {
          this.$message.error(data.message);
          _this.tableLoading = false;
        }
      } catch (err) {
        const { message } = err;
        _this.$message.error(message);
        _this.tableLoading = false;
      }
    }, 1000),
    handleSelectionChange(data) {
      let eslIds = [];
      data.forEach((item) => {
        eslIds.push(item.tagTypeId);
      });
      this.eslIds = eslIds;
    },
  },
  created() {
    this.getList();
  },
};
</script>
<style lang="scss" scoped>
.communication-records_top {
  margin-bottom: 20px;
  .record-text {
    font-size: 16px;
    color: #999999;
  }
}
.coor-state {
  padding: 3px 18px;
  text-align: center;
  border-radius: 15px;
  display: inline-block;
}
.state-connected {
  background: rgba(217, 250, 232, 1);
}
.state-connecting {
  background: rgba(193, 233, 255, 1);
}

.table-content ::v-deep {
  .el-table
    .el-table__header-wrapper
    .el-table__header
    .is-group
    tr:nth-of-type(2)
    th:nth-of-type(1)
    .cell {
    display: none;
  }
}
</style>