<template>
  <div class="esls-page">
    <div class="public-breadcrumb">
      <router-link style="color: #6e6b7b" to="/">{{
        $t("devices.Welcome")
      }}</router-link>
      > {{ $t("devices.Devices") }} >
      <span style="color: #3461ff">{{ $t("devices.ESLs") }}</span>
    </div>
    <div class="flex-between card-bg esls-page_top">
      <div class="flex-between transmit-btn-box">
        <el-popover
          placement="bottom"
          title=""
          popper-class="esls-page_transmit-btn_el-popover"
          min-width="160"
          trigger="click"
        >
          <div class="transmit-select">
            <div
              class="transmit-select-item"
              v-for="item in transmitSelectTypeList()"
              :key="item.value"
              @click="handelTransmitSelect(item.value)"
            >
              {{ item.name }}
            </div>
          </div>
          <el-button size="small" class="transmit-btn top-btn" slot="reference"
            >{{ $t("devices.esl.transmit") }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
        </el-popover>
      </div>
      <!-- <div class="right-btn">
        <el-button @click="handelExport" class="gray-btn btn"
          >Export<i class="el-icon-arrow-down el-icon--right"></i
        ></el-button>
      </div> -->
    </div>
    <div class="card-bg esls-page_content">
      <div class="esls-page_content-top flex-between">
        <div class="">
          <span>{{ $t("devices.records") }}: {{ pageParams.total }}</span>
          <el-select
            v-model="pageParams.storeQueryType"
            :placeholder="$t('devices.esl.Store Type Select')"
            size="small"
            @change="storeQueryTypeChange"
            style="width: 150px; margin-left: 10px"
          >
            <el-option
              v-for="(item, i) in storeTypeList()"
              :key="i"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
          <el-select
            v-model="pageParams.coorId"
            :placeholder="$t('devices.esl.APs Select')"
            size="small"
            clearable
            @change="getList"
            style="margin: 0 10px; width: 150px"
          >
            <el-option
              v-for="(item, i) in allStoreApList"
              :key="i"
              :label="item.coorName"
              :value="item.coorId"
            />
          </el-select>
          <el-select
            v-model="pageParams.tagTypeId"
            :placeholder="$t('devices.esl.ESL Type Select')"
            size="small"
            clearable
            @change="getList"
            style="width: 150px"
          >
            <el-option
              v-for="(item, i) in allTagTypeList"
              :key="i"
              :label="item.typeName"
              :value="item.tagTypeCodeId"
            />
          </el-select>
        </div>
        <div>
          <button class="refresh" @click="handleRefresh" ref="btn"><i class="el-icon-refresh"></i></button>

          <el-select
            v-model="pageParams.viewId"
            :placeholder="$t('devices.esl.View Type Select')"
            size="small"
            @change="changeView"
            style="margin: 0 10px; width: 150px"
          >
            <el-option
              v-for="(item, i) in viewList"
              :key="i"
              :label="item.viewName"
              :value="item.id"
            />
          </el-select>
          <el-tooltip
            placement="bottom"
            effect="light"
            popper-class="viewpopverClass"
          >
            <i class="el-icon-more pointer" :style="{height: '34px', lineHeight: '34px'}"></i>
            <div slot="content" class="view-popper">
              <p @click="handelCreateView">
                {{ $t("devices.esl.newBuild") }}
              </p>
              <p
                @click="handelEditeView"
                v-if="pageParams.viewId && defaultType != 0"
              >
                {{ $t("devices.esl.modify") }}
              </p>
              <p
                @click="handelCopyView"
                v-if="pageParams.viewId && defaultType != 0"
              >
                {{ $t("devices.esl.copy") }}
              </p>
              <p
                @click="handelDeleteView"
                v-if="pageParams.viewId && defaultType != 0"
              >
                {{ $t("devices.esl.delete") }}
              </p>
            </div>
          </el-tooltip>
        </div>
      </div>
      <div class="esls-page_content-table">
        <el-table
          :data="tableData"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          v-loading="tableLoading"
          @sort-change="handleSort"
        >
        
          <!-- @sort-change="handleSort" -->
          <el-table-column align="center" type="selection">
            <el-table-column
              align="center"
              width="55"
              type="selection"
              style="display: none"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column
            v-for="(headerItem, headerIndex) in colunmList"
            :key="headerItem.fieldName"
            :label="headerItem.columnName"
            align="center"
            :min-width="headerItem.fieldType === 'date' ? 300 : 160"
            :sortable="headerItem.sortFlag === true ? 'custom' : false" 
            :prop="headerItem.sortField"
          >
            <el-table-column
              align="center"
              :prop="headerItem.fieldName"
              :min-width="headerItem.fieldType === 'date' ? 300 : 160"
            >
              <template slot="header" slot-scope="scope">
                <el-input
                  v-if="
                    headerItem.fieldType === 'text' ||
                    headerItem.fieldType === 'number' ||
                    !headerItem.fieldType
                  "
                  v-model="headerItem.searchVal"
                  @input="handelSearchTableHeader(headerIndex, 'text', $event)"
                  clearable
                  size="small"
                />
                <el-date-picker
                  v-else-if="
                    headerItem.fieldType && headerItem.fieldType === 'date'
                  "
                  type="daterange"
                  start-placeholder=""
                  end-placeholder=""
                  v-model="headerItem.date"
                  size="small"
                  @input="handelSearchTableHeader(headerIndex, 'date', $event)"
                  placement="bottom-start"
                  style="width: 90%"
                  clearable
                />
                <el-select
                  v-else-if="
                    headerItem.fieldType && headerItem.fieldType === 'checkbox'
                  "
                  v-model="headerItem.searchVal"
                  @input="
                    handelSearchTableHeader(headerIndex, 'checkbox', $event)
                  "
                  clearable
                  placeholder=""
                  size="small"
                >
                  <el-option
                    v-for="(item, i) in headerItem.checkBoxList"
                    :key="i"
                    :label="item.name"
                    :value="item.id"
                    clearable
                  >
                  </el-option>
                </el-select>
              </template>
              <template slot-scope="scope">
                <!-- itemCode处理 -->
                <div
                  v-if="
                    headerItem.fieldName === 'goodsCode' &&
                    scope.row[headerItem.fieldName] &&
                    scope.row[headerItem.fieldName].length
                  "
                >
                  <div class="flex_ flex-column-center flex-row-center">
                    <div
                      style="margin-right: 10px"
                      class="flex_ flex-row-center"
                    >
                      <!-- <span class="text-ellipsis" style="width: 100px;display: inline-block;">
                        {{
                          scope.row[headerItem.fieldName] &&
                          scope.row[headerItem.fieldName].length
                            ? scope.row[headerItem.fieldName].join(",")
                            : ""
                        }}
                      </span>-->

                      <el-tooltip
                        effect="dark"
                        :content="
                          scope.row[headerItem.fieldName] &&
                          scope.row[headerItem.fieldName].length
                            ? scope.row[headerItem.fieldName].join(',')
                            : ''
                        "
                        placement="bottom"
                      >
                        <span
                          class="text-ellipsis"
                          style="width: 100px; display: inline-block"
                          >{{
                            scope.row[headerItem.fieldName] &&
                            scope.row[headerItem.fieldName].length
                              ? scope.row[headerItem.fieldName].join(",")
                              : ""
                          }}
                        </span>
                      </el-tooltip>
                    </div>
                    <el-tooltip
                      placement="right"
                      popper-class="esls-page_labe-image_el-popover"
                    >
                      <div slot="content" class="image-dialog">
                        <div class="title">
                          {{ $t("devices.esl.labelImage") }}
                        </div>

                        <div class="items">
                          <span class="items-title">
                            {{ $t("devices.esl.listAssociatedItems") }}:
                          </span>
                          <div class="item-table">
                            <div class="item-table-header flex-between">
                              <div>{{ $t("devices.esl.code") }}</div>
                              <div>{{ $t("devices.esl.name") }}</div>
                            </div>
                            <div
                              v-for="(item, i) in scope.row.goodsCode"
                              :key="i"
                            >
                              <div
                                class="item-table-list"
                                v-show="i == j"
                                v-for="(itemName, j) in scope.row.goodsName"
                                :key="j"
                              >
                                <span>{{ item }}</span>
                                <el-tooltip
                                  class="item"
                                  effect="dark"
                                  :content="itemName"
                                  placement="right-start"
                                >
                                  <span class="item-name text-ellipsis"
                                    ><icon-svg
                                      name="item_name_image"
                                      style="margin-right: 5px"
                                    ></icon-svg
                                    >{{ itemName }}
                                  </span>
                                </el-tooltip>
                              </div>
                            </div>
                          </div>

                          <div class="details">
                            <div class="title">
                              {{ $t("devices.esl.details") }}:
                            </div>
                            <div
                              class="details-image"
                              :class="{
                                'have-picture': scope.row.imageSrc,
                                'no-picture': !scope.row.imageSrc,
                              }"
                            >
                              <img :src="scope.row.imageSrc" alt="" srcset="" />
                            </div>
                          </div>

                          <div class="items-types">
                            <div class="item-type">
                              {{ $t("devices.esl.eSLType") }}:
                              {{ scope.row.tagTypeName }}
                            </div>
                            <div class="item-type">
                              {{ $t("devices.esl.lastAssociated") }}:
                              {{ scope.row.lastBindTime }}
                            </div>
                            <div class="item-type">
                              {{ $t("devices.esl.lastUpdate") }}:
                              {{ scope.row.updateTime }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <i class="el-icon-search search-icon pointer"></i>
                    </el-tooltip>
                  </div>
                </div>

                <!-- 其他数组类型处理 -->
                <!-- <div
                  v-else-if="
                    Array.isArray(scope.row[headerItem.fieldName]) &&
                    headerItem.fieldName !== 'goodsCode'
                  "
                  class="text-ellipsis"
                >
                  {{
                    scope.row[headerItem.fieldName] &&
                    scope.row[headerItem.fieldName].length
                      ? scope.row[headerItem.fieldName].join(",")
                      : ""
                  }}
                </div> -->
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="
                    scope.row[headerItem.fieldName] &&
                    scope.row[headerItem.fieldName].length
                      ? scope.row[headerItem.fieldName].join(',')
                      : ''
                  "
                  placement="bottom"
                  v-else-if="
                    Array.isArray(scope.row[headerItem.fieldName]) &&
                    headerItem.fieldName !== 'goodsCode'
                  "
                >
                  <span class="text-ellipsis" style="min-width: 120px"
                    >{{
                      scope.row[headerItem.fieldName] &&
                      scope.row[headerItem.fieldName].length
                        ? scope.row[headerItem.fieldName].join(",")
                        : ""
                    }}
                  </span>
                </el-tooltip>

                <!-- 不为数组 -->
                <template
                  v-else-if="!Array.isArray(scope.row[headerItem.fieldName])"
                >
                  <!-- tagState字段 -->
                  <span
                    v-if="headerItem.fieldName == 'status'"
                    class="else-state"
                    :class="{
                      'state-blue':
                        scope.row.tagState == ESLE_STATE_ACTIVE ||
                        scope.row.tagState == ESLE_STATE_UPDATING ||
                        scope.row.tagState == ESLE_STATE_SUCCESS,
                      'state-red':
                        scope.row.tagState == ESLE_STATE_FAILED ||
                        scope.row.tagState == ESLE_STATE_UNACTIVE,
                    }"
                  >
                    {{ scope.row.tagStateName }}
                  </span>

                  <!-- lastTaskType字段 -->
                  <span v-if="headerItem.fieldName == 'lastTaskType'">
                    {{ scope.row.lastTaskTypeName }}
                  </span>

                  <!-- tagTypeId 字段 -->
                  <span v-else-if="headerItem.fieldName == 'tagTypeId'">
                    {{ scope.row.tagTypeName }}
                  </span>

                  <!-- tagPower 字段 -->
                  <span v-else-if="headerItem.fieldName == 'tagPower'">
                    {{ scope.row.tagPowerName }}
                  </span>
                  <!-- Connectivity字段 -->
                  <span
                    v-else-if="
                      headerItem.fieldName === 'connectivity' &&
                      scope.row.connectivityName
                    "
                  >
                    <icon-svg
                      style="margin-right: 5px"
                      name="signal"
                      class="green-color"
                      :class="{
                        'red-color': scope.row[headerItem.fieldName] == 0,
                      }"
                    ></icon-svg>
                    <span>{{ scope.row.connectivityName }}</span>
                  </span>
                  <span v-else>{{ scope.row[headerItem.fieldName] }} </span>
                </template>
              </template>
            </el-table-column>
          </el-table-column>

          <el-table-column
            align="center"
            fixed="right"
            :label="$t('devices.Action')"
            width="130"
          >
            <el-table-column align="center" width="130">
              <template slot-scope="{ row }">
                <div class="flex_ flex-column-center flex-row-center">
                  <img
                    src="@/assets/img/ESL_history_icon.png"
                    @click="handelViewRecords(row)"
                    alt=""
                    style="width: 18px; cursor: pointer"
                  />
                  <img
                    src="@/assets/img/esl_info.png"
                    @click="handelItemDetail(row)"
                    alt=""
                    style="
                      width: 17px;
                      height: 17px;
                      cursor: pointer;
                      margin: 0 12px;
                    "
                  />
                  <img
                    src="@/assets/img/ESL_wifi.png"
                    alt=""
                    style="width: 15px; herght: 15px; cursor: pointer"
                    @click="handelTransmitSingle(row)"
                  />
                </div>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
        <pagination :tableInfo="pageParams" @changePage="changePage" />
      </div>
    </div>
    <el-dialog
      :title="$t('devices.esl.ESLUpdateRecords')"
      :visible.sync="isVisibleRecordsDialog"
      class="communication-records_dialog"
      width="1100px"
      :close-on-click-modal="false"
    >
      <ESL-update-records
        v-if="isVisibleRecordsDialog"
        :currentItem="currentItem"
        :taskTypeList="taskTypeList"
        :elsStateList="elsStateList"
      ></ESL-update-records>
    </el-dialog>
    <!-- 详情 -->
    <el-dialog
      :title="$t('devices.esl.itemDetail')"
      :visible.sync="isVisibleDetailDialog"
      class="communication-records_dialog"
      width="1100px"
      :close-on-click-modal="false"
    >
      <item-detail
        v-if="isVisibleDetailDialog"
        :currentItem="currentItem"
        :stateList="elsStateList"
      ></item-detail>
      <div slot="footer" class="dialog-footer">
        <el-button
          class="save-btn btn"
          size="medium"
          type="primary"
          @click="isVisibleDetailDialog = false"
          >{{ $t("devices.ok") }}</el-button
        >
      </div>
    </el-dialog>
    <!-- 创建视图列表 $t('devices.esl.selectColumn')-->
    <el-dialog
      :title="viewDialogTitle"
      :visible.sync="isVisibleViewDialog"
      width="800px"
      class="communication-records_dialog"
      :close-on-click-modal="false"
    >
      <select-column
        ref="viewForm"
        v-if="isVisibleViewDialog"
        :columnList="viewList"
        :viewId="pageParams.viewId"
        :clickViewType="clickViewType"
      ></select-column>
      <div slot="footer" class="dialog-footer">
        <el-button
          class="cancel-btn btn"
          size="small"
          @click="isVisibleViewDialog = false"
          >{{ $t("devices.cancel") }}</el-button
        >
        <el-button
          class="save-btn btn"
          size="small"
          type="primary"
          @click="handelSaveView"
          :loading="viewLoading"
          >{{ $t("devices.ok") }}</el-button
        >
      </div>
    </el-dialog>
    <!-- TransmitByAP -->
    <el-dialog
      :title="$t('devices.esl.TransmitByAP')"
      :visible.sync="isVisibleTransmitByApDialog"
      width="1000px"
      class="communication-records_dialog"
      :close-on-click-modal="false"
    >
      <transmit-by-ap
        ref="byApTable"
        v-if="isVisibleTransmitByApDialog"
      ></transmit-by-ap>
      <div slot="footer" class="dialog-footer">
        <el-button
          class="cancel-btn btn"
          size="small"
          @click="isVisibleTransmitByApDialog = false"
          >{{ $t("devices.cancel") }}</el-button
        >
        <el-button
          class="save-btn btn"
          size="small"
          type="primary"
          @click="handelTransmitByAp"
          :loading="viewLoading"
          >{{ $t("devices.ok") }}</el-button
        >
      </div>
    </el-dialog>

    <!-- Transmit By ESL Type -->
    <el-dialog
      :title="$t('devices.esl.TransmitByESLType')"
      :visible.sync="isVisibleTransmitByEslDialog"
      width="1000px"
      class="communication-records_dialog"
      :close-on-click-modal="false"
    >
      <transmit-by-esl-type
        v-if="isVisibleTransmitByEslDialog"
        :allTagTypeList="allTagTypeList"
        ref="byElsTypeTable"
      ></transmit-by-esl-type>
      <div slot="footer" class="dialog-footer">
        <el-button
          class="cancel-btn btn"
          size="small"
          @click="isVisibleTransmitByEslDialog = false"
          >{{ $t("devices.cancel") }}</el-button
        >
        <el-button
          class="save-btn btn"
          size="small"
          type="primary"
          @click="handelTransmitByEls"
          :loading="viewLoading"
          >{{ $t("devices.ok") }}</el-button
        >
      </div>
    </el-dialog>

    <ExportDialog
      v-if="isVisibleExportDialog"
      :isVisible="isVisibleExportDialog"
      @handelCancel="isVisibleExportDialog = false"
      @handelSubmit="handelSubmitExport"
      :exportTypeList="ImportTypeList"
      :exportType="2501"
    />

    <confirm-dialog
      :dialogVisible="isVisibleConfirmDialog"
      @handleClose="handelConfirmDialog"
      :confirmObj="confirmObj"
    >
    </confirm-dialog>
  </div>
</template>
<script>
import { debounce } from "lodash";
import moment from "moment";
import ESLUpdateRecords from "./modules/ESLUpdateRecords";
import SelectColumn from "./modules/SelectColumn";
import ItemDetail from "./modules/ItemDetail";
import TransmitByAp from "./modules/TransmitByAp";
import TransmitByEslType from "./modules/TransmitByEslType";
import ExportDialog from "@/components/ExportDialog";
import { commonStore } from "@/store/global-select-store";
import pagination from "@/components/pagination";
import ConfirmDialog from "@/components/confirm-dialog/index.vue";
import { getStoreAllApList, getStoreApList, getCodeList } from "@/libs/api/aps";
import {
  getList,
  getAllTagType,
  getViewList,
  getViewCreate,
  getViewUpdate,
  getViewDelete,
  batchDelete,
  pushTag,
  batchPushTag,
  pushTagByType,
  pushTagByCoor,
} from "@/libs/api/esls";
import {
  ESLE_STATE_UNACTIVE,
  ESLE_STATE_ACTIVE,
  ESLE_STATE_UPDATING,
  ESLE_STATE_SUCCESS,
  ESLE_STATE_FAILED,
  transmitSelectTypeList,
  storeTypeList,
  paginationEnum,
  // ImportTypeList,
} from "@/libs/enum";
export default {
  name: "ApsIndex",
  components: {
    ESLUpdateRecords,
    SelectColumn,
    ItemDetail,
    TransmitByAp,
    TransmitByEslType,
    ExportDialog,
    pagination,
    ConfirmDialog,
  },
  data() {
    return {
      commonStore,
      paginationEnum,
      ImportTypeList: [
        {
          label: 2501,
          name: "Excel",
        },
      ],

      transmitSelectTypeList,
      storeTypeList,
      ESLE_STATE_UNACTIVE,
      ESLE_STATE_ACTIVE,
      ESLE_STATE_UPDATING,
      ESLE_STATE_SUCCESS,
      ESLE_STATE_FAILED,
      taskTypeList: [], //任务类型
      elsStateList: [], //价签状态
      allTagTypeList: [], //所有价签类型
      allStoreApList: [], //当前门店所有的基站列表
      moment,
      isVisibleRecordsDialog: false,
      isVisibleDetailDialog: false, //详情
      isVisibleTransmitByApDialog: false, //AP推送
      isVisibleTransmitByEslDialog: false, //ESL推送
      isVisibleExportDialog: false, // 导出
      isVisibleConfirmDialog: false, //确认弹框
      pageParams: {
        current: 1,
        size: 10,
        total: 0,

        date: [],
        coorId: "",

        storeQueryType: 2,
        tagTypeId: "", //价签类型
        lastTaskTypeId: "", //任务类型

        isEffect: 0,

        viewId: "", //视图id
        orders: [],
      },
      defaultType: 1, // 视图类型 -1:个人默认 0：系统默认 1：非默认
      tableData: [],
      currentItem: {},
      tagIds: [],
      loading: false,
      tableLoading: false,

      tableHeaderInputCondtion: {}, //表头搜索字段
      colunmList: [], //动态渲染的column
      isVisibleViewDialog: false, // 视图弹框
      clickViewType: "",
      viewList: [], //视图列表
      scenarioId: "", // '1575022040222846976'    1570342756224983040
      viewLoading: false, //保存视图的loading

      confirmObj: {
        type: this.$t("devices.tips"),
        cancelText: this.$t("devices.cancel"),
        confirmText: this.$t("devices.sure"),
        content: this.$t("devices.esl.repushTips"),
        loading: false,
      },
      currentConfirmType: "",  

      init: true, // 是否首次进入页面
      viewDialogTitle: "",
    };
  },
  methods: {
    // 刷新
    handleRefresh(){
      this.tableLoading = true
      this.getList();
    },
    changePage() {
      this.getList();
    },
    handelExport() {
      this.isVisibleExportDialog = true;
    },
    // 门店类型切换
    storeQueryTypeChange(){
      this.getList()
      this.getStoreApList()
    },
    async getStoreApList() {
      try {
        const params = {
          storeId:localStorage.getItem("storeId"),
          storeType: this.pageParams.storeQueryType,
          storeName: '',
          remark:  '',
          startDate:'',
          endDate: '',
          ipAddr: '',
          coorName:'',
          coorState: '',
          startTags: '',
          endTags:'',
          current:1,
          size: 10,
          orders:[],
      };
        const { data } = await getStoreApList(params);
        // console.log('data----',data)
        if (data.state === true) {
          let arr = data.data.records || [];
          // console.log({arr})
          this.allStoreApList = arr;
        } else {
          this.$message.error(data.message);
        }
      } catch (err) {
        const { message } = err;
        this.$message.error(message);
      }
    },
    // 排序
    handleSort(column) {
      let arr = []
      if(column.order){
        let order= column.order === "ascending" ? "asc" : "desc";
        order = order + ':'+ column.prop;
        arr.push(order);
      } else {
        arr = [];
      }
      this.pageParams.orders = arr;
      this.getList();
    },

    handelConfirmDialog(val) {
      if (val === "confirm") {
        const { currentConfirmType } = this;
        
        this.confirmObj.loading = true;
        if (currentConfirmType === "transmitSingle") {
          //单个重推
          this.doTransmittSingleConfirm();
        }

        if (currentConfirmType === "pushTagByType") {
          //根据价签类型批量推送
          this.doTransmittByTypeConfirm();
        }

        if (currentConfirmType === "batchPushTag") {
          //selected批量推送
          this.doTransmittBySelectedConfirm();
        }

        if (currentConfirmType === "pushTagByCoor") {
          //根据基站推送
          this.doTransmittByCoorConfirm();
        }

        if (currentConfirmType === "getViewDelete") {
          // 删除视图
          this.doDeleteViewConfirm();
        }
      } else {
        this.isVisibleConfirmDialog = false;
        this.confirmObj.loading = false;
      }
    },
    //do 删除视图
    async doDeleteViewConfirm() {
      try {
        const params = {
          id: this.pageParams.viewId,
        };
        const { data } = await getViewDelete(params);
        if (data.state === true) {
          this.isVisibleConfirmDialog = false;
          // this.pageParams.viewId = "";
          this.confirmObj.loading = false;
          this.$message.success(this.$t("devices.deleteSuccess"));
          this.init = true
          this.getViewList();
          this.getList();
        } else {
          this.confirmObj.loading = false;
          this.$message.error(data.message);
        }
      } catch (err) {
        this.confirmObj.loading = false;
        const { message } = err;
        this.$message.error(message);
      }
    },
    //do根据基站推送
    async doTransmittByCoorConfirm() {
      try {
        const apIds = this.$refs.byApTable.apIds || [];
        const params = {
          coorIdList: apIds,
        };
        const { data } = await pushTagByCoor(params);
        if (data.state === true) {
          this.isVisibleConfirmDialog = false;
          this.isVisibleTransmitByApDialog = false;
          this.confirmObj.loading = false;
          this.$message.success(this.$t("devices.operationSuccess"));
          this.getList();
        } else {
          this.confirmObj.loading = false;
          this.$message.error(data.message);
        }
      } catch (err) {
        this.confirmObj.loading = false;
        const { message } = err;
        this.$message.error(message);
      }
    },
    // do selected批量推送
    async doTransmittBySelectedConfirm() {
      try {
        const params = {
          tagIds: this.tagIds.join(","),
        };
        const { data } = await batchPushTag(params);
        if (data.state === true) {
          this.isVisibleConfirmDialog = false;
          this.confirmObj.loading = false;
          this.$message.success(this.$t("devices.operationSuccess"));
          this.getList();
        } else {
          this.confirmObj.loading = false;
          this.$message.error(data.message);
        }
      } catch (err) {
        this.confirmObj.loading = false;
        const { message } = err;
        this.$message.error(message);
      }
    },
    // do根据价签类型批量推送
    async doTransmittByTypeConfirm() {
      try {
        const eslIds = this.$refs.byElsTypeTable.eslIds || [];
        const params = {
          tagTypeIdList: eslIds,
          storeId: localStorage.getItem("storeId"),
        };
        const { data } = await pushTagByType(params);
        if (data.state === true) {
          this.isVisibleConfirmDialog = false;
          this.isVisibleTransmitByEslDialog = false;
          this.confirmObj.loading = false;
          this.$message.success(this.$t("devices.operationSuccess"));
          this.getList();
        } else {
          this.confirmObj.loading = false;
          this.$message.error(data.message);
        }
      } catch (err) {
        this.confirmObj.loading = false;
        const { message } = err;
        this.$message.error(message);
      }
    },
    // do单个推送
    async doTransmittSingleConfirm() {
      try {
        const { data } = await pushTag({ tagId: this.currentItem.tagId });
        if (data.state === true) {
          this.isVisibleConfirmDialog = false;
          this.confirmObj.loading = false;
          this.$message.success(this.$t("devices.operationSuccess"));
          this.getList();
        } else {
          this.confirmObj.loading = false;
          this.$message.error(data.message);
        }
      } catch (err) {
        this.confirmObj.loading = false;
        const { message } = err;
        this.$message.error(message);
      }
    },
    // 重推单个价签
    handelTransmitSingle(item) {
      this.currentItem = item;
      this.isVisibleConfirmDialog = true;
      this.confirmObj.content = this.$t("devices.esl.repushTips");
      this.currentConfirmType = "transmitSingle";
    },
    // 根据价签类型批量推送
    handelTransmitByEls() {
      const eslIds = this.$refs.byElsTypeTable.eslIds || [];
      if (!eslIds.length) {
        this.$message({
          message: this.$t("devices.selectedNoData"),
          type: "warning",
        });
        return;
      }
      this.isVisibleConfirmDialog = true;
      this.confirmObj.content = this.$t("devices.esl.repushTips");
      this.currentConfirmType = "pushTagByType";
    },
    // 根据基站批量推送
    handelTransmitByAp() {
      const apIds = this.$refs.byApTable.apIds || [];
      if (!apIds.length) {
        this.$message({
          message: this.$t("devices.selectedNoData"),
          type: "warning",
        });
        return;
      }
      this.isVisibleConfirmDialog = true;
      this.confirmObj.content = this.$t("devices.esl.repushTips");
      this.currentConfirmType = "pushTagByCoor";
    },
    handelTransmitSelect(val) {
      if (val === "AP") {
        this.isVisibleTransmitByApDialog = true;
      }
      if (val === "ESL") {
        this.isVisibleTransmitByEslDialog = true;
      }

      if (val === "Selected") {
        if (!this.tagIds.length) {
          this.$message({
            message: this.$t("devices.selectedNoData"),
            type: "warning",
          });
          return;
        }
        this.isVisibleConfirmDialog = true;
        this.confirmObj.content = this.$t("devices.esl.repushTips");
        this.currentConfirmType = "batchPushTag";
      }
    },
    handelSearchImage(item) {
      this.currentItem = item;
    },
    handelSearchTableHeader(index, type, e) {
      const item = this.colunmList[index];
      let value = e;
      if (value && type === "text") {
        value = value.trim();
      }
      this.tableHeaderInputCondtion[item.fieldName] = value;
      if (type === "date") {
        const dateN = value || [];
        if (dateN && dateN.length) {
          // let lastTransmissionStart = moment(dateN[0]).format("YYYY-MM-DD");
          // let lastTransmissionEnd = moment(dateN[1]).format("YYYY-MM-DD");
          let lastTransmissionStart = dateN[0];
          let lastTransmissionEnd = dateN[1];
          this.tableHeaderInputCondtion.lastTransmissionStart =
            lastTransmissionStart;
          this.tableHeaderInputCondtion.lastTransmissionEnd =
            lastTransmissionEnd;
        } else {
          this.tableHeaderInputCondtion.lastTransmissionStart = "";
          this.tableHeaderInputCondtion.lastTransmissionEnd = "";
        }
        this.$set(this.colunmList[index], "date", dateN);
      }
      this.$set(this.colunmList[index], "searchVal", value);
      this.$forceUpdate();
      this.getList();
    },
    handelCreateView() {
      this.clickViewType = "add";
      this.isVisibleViewDialog = true;

      this.viewDialogTitle = this.$t("devices.esl.newBuild");
    },
    handelEditeView() {
      this.clickViewType = "edite";
      this.isVisibleViewDialog = true;

      this.viewDialogTitle = this.$t("devices.esl.modify");
    },
    handelCopyView() {
      this.clickViewType = "copy";
      this.isVisibleViewDialog = true;

      this.viewDialogTitle = this.$t("devices.esl.copy");
    },
    handelDeleteView() {
      this.isVisibleConfirmDialog = true;
      this.currentConfirmType = "getViewDelete";
      this.confirmObj.content = this.$t("devices.esl.deleteViewTips");
    },
    handelDeleteAll() {
      const { tagIds } = this;
      if (!tagIds.length) {
        this.$message({
          message: this.$t("devices.selectedNoData"),
          type: "warning",
        });
        return;
      }

      this.$confirm("Are you sure you want to reset?", "Tips", {
        confirmButtonText: "Sure",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(async () => {
          try {
            const params = {
              tagIds: this.tagIds.join(","),
            };
            const { data } = await batchDelete(params);
            if (data.state === true) {
              this.$message.success(this.$t("devices.operationSuccess"));
              this.getList();
            } else {
              this.$message.error(data.message);
            }
          } catch (err) {
            const { message } = err;
            this.$message.error(message);
          }
        })
        .catch(() => {});
    },
    // 详情
    handelItemDetail(item) {
      this.currentItem = item;
      if (!this.currentItem.tagState) {
        this.currentItem.tagState = [];
      } else {
        this.currentItem.tagState = [this.currentItem.tagState];
      }
      this.isVisibleDetailDialog = true;
    },
    handelViewRecords(item) {
      this.currentItem = item;
      this.isVisibleRecordsDialog = true;
    },

    // 新增修改复制视图
    handelSaveView() {
      try {
        this.$refs.viewForm.$refs["ruleForm"].validate(async (valid) => {
          if (valid) {
            const viewForm =
              this.$refs && this.$refs.viewForm && this.$refs.viewForm
                ? this.$refs.viewForm
                : {};
            const { ruleForm, viewQueryCondition } = viewForm;

            let viewQueryConditionNew = [];
            viewQueryCondition.forEach((item) => {
              if (item.columnName) {
                let fieldValue = item.fieldValue;
                if (fieldValue instanceof Array) {
                  fieldValue = fieldValue.join(",");
                }
                viewQueryConditionNew.push({
                  fieldType: item.fieldType,
                  columnName: item.columnName,
                  condtion: item.condtion,
                  fieldName: item.columnName,
                  fieldValue: fieldValue,
                });
              }
            });
            const { viewName, openType, defaultType } = ruleForm;

            const params = {
              id: this.clickViewType === "edite" ? this.pageParams.viewId : "",
              storeId: localStorage.getItem("storeId"),
              viewType: 1, //(0: item 1: 标签)
              defaultType: defaultType ? -1 : 1, //(-1:个人默认1:非默认)
              viewName,
              openType,
              scenarioId: this.scenarioId,
              viewListField: ruleForm.checkFieldList,
              viewQueryCondition: viewQueryConditionNew,
            };
            this.viewLoading = true;

            let res = {};
            if (this.clickViewType === "edite") {
              res = await getViewUpdate(params);
            } else {
              res = await getViewCreate(params);
            }
            let data = res.data;

            if (data.state === true) {
              this.isVisibleViewDialog = false;
              this.$message.success(this.$t("devices.saveSuccess"));
              this.getViewList();
              this.getList();
            } else {
              this.$message.error(data.message);
            }
            this.viewLoading = false;
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      } catch (err) {
        const { message } = err;
        this.$message.error(message);
        this.viewLoading = false;
      }
    },
    getList: debounce(async function (val) {
      const _this = this;
      let storeId = localStorage.getItem("storeId");
      const params = {
        // storeId: sessionStorage.getItem("storeId"),
        storeId,
        storeQueryType: _this.pageParams.storeQueryType,
        coorId: _this.pageParams.coorId, // 基站选择
        tagTypeId: _this.pageParams.tagTypeId, //价签类型
        lastTaskTypeId: _this.pageParams.lastTaskTypeId, //任务类型
        ...this.tableHeaderInputCondtion,

        isEffect: 0,
        viewId: _this.pageParams.viewId,

        current: _this.pageParams.current,
        size: _this.pageParams.size,
        orders: _this.pageParams.orders,
      };

      if (params.updateTime) {
        delete params.updateTime;
      }

      try {
        _this.tableLoading = true;
        // console.log(this.timer, 'this.timer请求接口前时间')
        const { data } = await getList(params);
        if (data.state === true) {
          // console.log(this.timer, 'this.timer请求接口成功时间')
          let dataNew = data.data || {};
          _this.tableData = dataNew.records || [];
          _this.pageParams.total = dataNew.total || 0;
          _this.tableLoading = false;

          

        _this.tableData.forEach((item)=> {
          let tagPowerName = ''
          if(item.tagPower >= 3.08) {
            tagPowerName = this.$t('devices.esl.Good');
          } else if(item.tagPower > 2.2 && item.tagPower < 3.08) {
            tagPowerName = this.$t('devices.esl.Normal');
          } else {
            tagPowerName = this.$t('devices.esl.Low');
          }
          _this.$set(item, 'tagPowerName', tagPowerName);
        })

          const businessViewEntity = dataNew.businessViewEntity || {};
          const selectMap = dataNew.selectMap || {};
          let viewListFields = businessViewEntity.viewListFields;
          if (viewListFields && typeof viewListFields === "string") {
            viewListFields = JSON.parse(viewListFields);
          }

          let colunmListN = JSON.parse(JSON.stringify(this.colunmList));
          viewListFields.forEach((item) => {
            this.$set(item, "searchVal", "");
            if (item.type === "date") this.$set(item, "date", []);
            colunmListN.forEach((itemVal) => {
              if (item.fieldName === itemVal.fieldName) {
                if (item.type === "date") {
                  item.date = itemVal.date ? itemVal.date : [];
                } else {
                  this.$set(
                    item,
                    "searchVal",
                    itemVal.searchVal ? itemVal.searchVal : ""
                  );
                }
              }
            });
            item.fieldType = item.type;

            if (item.fieldType === "checkbox") {
              for (let k in selectMap) {
                if (k === item.listKey) {
                  item.checkBoxList = selectMap[k];
                  break;
                }
              }
            }
          });
          this.colunmList = viewListFields;

          this.defaultType = businessViewEntity.defaultType;
        } else {
          this.$message.error(data.message);
          _this.tableLoading = false;
        }
      } catch (err) {
        const { message } = err;
        _this.$message.error(message);
        _this.tableLoading = false;
      }
    }, 1000),
    handleSelectionChange(data) {
      let tagIds = [];
      data.forEach((item) => {
        tagIds.push(item.tagId);
      });
      this.tagIds = tagIds;
    },
    async getCodeList(type) {
      try {
        const { data } = await getCodeList({ cateId: type });
        if (data.state === true) {
          let arr = data.data || [];
          if (type == 12) {
            this.taskTypeList = arr; //任务类型
          } else if (type == 10) {
            this.elsStateList = arr; //价签状态
          }
        } else {
          this.$message.error(data.message);
        }
      } catch (err) {
        const { message } = err;
        this.$message.error(message);
      }
    },
    async getAllTagType() {
      try {
        const { data } = await getAllTagType({ merchantId: "", source: "" });
        if (data.state === true) {
          let arr = data.data || [];
          this.allTagTypeList = arr;
        } else {
          this.$message.error(data.message);
        }
      } catch (err) {
        const { message } = err;
        this.$message.error(message);
      }
    },
    async getStoreAllApList() {
      try {
        const { data } = await getStoreAllApList({
          storeId: localStorage.getItem("storeId"),
          macAddress: "",
        });
        if (data.state === true) {
          let arr = data.data || [];
          this.allStoreApList = arr;
        } else {
          this.$message.error(data.message);
        }
      } catch (err) {
        const { message } = err;
        this.$message.error(message);
      }
    },

    //获取视图选择数据list下拉框
    async getViewList() {
      try {
        const { data } = await getViewList({
          viewName: "",
          scenarioId: this.scenarioId,
        });
        if (data.state === true) {
          this.viewList = data.data || [];
          if(this.init && this.viewList && this.viewList.length) {
            this.pageParams.viewId = this.viewList[0]["id"];
          }
          // this.pageParams.viewId =
          //   this.viewList && this.viewList.length ? this.viewList[0]["id"] : "";
        } else {
          this.$message.error(data.message);
        }
      } catch (err) {
        const { message } = err;
        this.$message.error(message);
      }
    },
    async changeView() {
      //只有首次进入页面才会显示默认值
      this.init = false;
      await this.getList()
    },
    // 导出
    handelSubmitExport() {},
  },
  created() {
    commonStore.setInjectCallBack(this.getList);
    this.getList();
    this.getCodeList(12); //任务类型
    this.getCodeList(10); //价签状态
    this.getAllTagType();
    this.getStoreAllApList();

    this.getViewList();

    // setInterval(() => {
    //   this.timer = moment(new Date().getTime()).format('YYYY-MM-DD HH:mm:ss');
    // }, 100);
  },
  computed: {
    language() {
      return this.$store.state.appConfig.language;
    },
  },
  watch: {
    language(val) {
      if (val) this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
.red-color {
  color: rgba(255, 30, 30, 1) !important;
}
.green-color {
  color: rgba(40, 192, 24, 1);
}
.flex_ {
  display: flex;
}
.flex-column-center {
  align-items: center;
}
.flex-row-center {
  justify-content: center;
}

.transmit-select {
  .transmit-select-item {
    height: 30px;
    line-height: 30px;
    padding-left: 10px;
    font-size: 14px;
    color: #666;
    cursor: pointer;
  }
}
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.image-dialog {
  border-radius: 6px;
  .title {
    font-size: 18px;
    font-weight: 400;
    color: #ffffff;
    margin-bottom: 20px;
    height: 60px;
    line-height: 60px;
    background: hsl(226deg 29% 17%);
    padding: 0 21px;
    border-radius: 6px;
  }
  .items {
    padding: 0 21px;
    .items-title {
      font-size: 16px;
      color: #ffffff;
      border-bottom: 1px solid #ffffff;
    }
    .item-table {
      .item-table-header {
        padding: 0 10px;
        display: flex;
        // justify-content: space-between;
        align-items: center;
        margin: 10px 0;
        font-size: 16px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.8);
        padding: 0 12px;
        div {
          width: 40%;
        }
      }
      .item-table-list {
        height: 32px;
        line-height: 32px;
        background: #6f799a;
        border-radius: 6px;
        padding: 10px;

        display: flex;
        // justify-content: space-between;
        align-items: center;
        margin-bottom: 2px;

        span {
          width: 40%;
        }
        .item-name {
          width: 55%;
        }
      }
    }

    .details {
      .title {
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        background: #282f45;
        height: 20px;
        line-height: 20px;
        padding: 0;
        margin: 20px 0;
      }
      border-top: 1px solid rgba(54, 63, 92, 0.9);
      margin-top: 20px;
      .details-image {
        padding: 0 20px 20px;
        width: 100%;
      }
      .have-picture {
        height: auto;
        img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
        }
      }
      .no-picture {
        height: 200px !important;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .items-types {
      margin-bottom: 25px;
      .item-type {
        font-size: 16px;
        color: #ffffff;
        line-height: 24px;
      }
    }
  }
}
.esls-page {
  .refresh{
    background: #35b871;
    border: none;
    padding: 7px 9px;
    border-radius: 4px;
    color: #fff;
  }
  .pointer {
    cursor: pointer;
  }
  .search-icon {
    width: 31px;
    height: 31px;
    background: #edfff5;
    border-radius: 16px;
    color: rgba(53, 184, 113, 1);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .flex-between {
    display: flex;
    justify-content: space-between;
  }
  .card-bg {
    background: #ffffff;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
  }
  &_top ::v-deep {
    padding: 20px;
    margin-bottom: 20px;
    .right-btn,
    .transmit-btn-box {
      .el-button > span {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    .el-button {
      width: 140px;
      // height: 40px;
      height: 34px;
      border-radius: 6px;
      // font-size: 18px;
      padding: 0 16px;
      color: #ffffff;
      i {
        margin-right: 5px !important;
      }
      .el-input {
        height: 100%;
        .el-input__inner {
          height: 100%;
        }
      }
    }
    // transmit下拉框
    .transmit-btn.el-button {
      border: none;
      margin-right: 20px;
      background: #3461ff;
      color: #ffffff;
      border-radius: 6px;
    }
    .danger-btn {
      background: #fc184d;
      color: #ffffff;
    }
    .gray-btn {
      background: #eeeeee;
      border: 1px solid #eeeeee;
      color: #666666;
      width: 80px;
    }
    .top-btn {
      // font-size: 16px;
    }
  }
  &_content {
    &-top {
      padding: 15px;
      font-size: 12px;
      // color: #999999;
      > span {
        margin-right: 10px;
      }
    }
    &-table ::v-deep {
      .el-table {
        .el-table__header-wrapper {
          .el-table__header {
            width: 100% !important;
          }
        }

        .el-table__header-wrapper,
        .el-table__empty-block {
          width: 100% !important;
        }
      }
      .el-table
        .el-table__header-wrapper
        .el-table__header
        .is-group
        tr:nth-of-type(2)
        th:nth-of-type(1)
        .cell {
        display: none;
      }
      .else-state {
        padding: 3px 18px;
        text-align: center;
        border-radius: 15px;
        display: inline-block;
      }
      .state-red {
        background: rgba(254, 202, 202, 1);
      }

      .state-blue {
        background: rgba(193, 233, 255, 1);
      }
    }
  }
  .dialog-footer ::v-deep {
    text-align: center;
    .el-button {
      width: 110px;
      height: 40px;
      border: none;
    }
    .el-button.cancel-btn {
      background: #d4d4d4 !important;
      box-shadow: 0px 1px 15px 0px rgba(99, 99, 99, 0.23);
      color: #626262;
    }
    .el-button.save-btn {
      background: #35b871 !important;
      box-shadow: 0px 1px 15px 0px rgba(53, 184, 113, 0.55);
      color: #ffffff;
    }
  }
  .communication-records_dialog ::v-deep {
    .el-dialog__body {
      padding: 30px 20px !important;
    }
  }
}
</style>