<template>
  <div class="table-content">
    <div class="communication-records_top">
      <span class="record-text"> {{$t('devices.records')}}: {{ pageParams.total }} </span>
    </div>
    <el-table
      border
      :data="tableData"
      style="width: 100%"
      v-loading="tableLoading"
      @selection-change="handleSelectionChange"
      @sort-change="handleSort"
    >
      <el-table-column align="center" type="selection">
        <el-table-column
          align="center"
          width="55"
          type="selection"
          style="display: none"
        >
        </el-table-column>
      </el-table-column>
      <el-table-column align="center" width="55" type="index" :label="$t('devices.esl.No')">
        <el-table-column type="index" align="center" width="55"> </el-table-column>
      </el-table-column>

       <el-table-column
        align="center"
        width="180"
        prop="storeName"
        :label="$t('devices.esl.storeName')"
      >
        <el-table-column align="center" prop="storeName">
          <template slot="header" slot-scope="scope">
            <el-input
              v-model="pageParams.storeName"
              @input="getList"
              size="small"
              placeholder=""
              clearable
            />
          </template> </el-table-column
      ></el-table-column>
      <el-table-column
        align="center"
        width="180"
        prop="coorState"
        :label="$t('devices.esl.connectionStatus')"
      >
        <el-table-column align="center" prop="coorState">
          <template slot="header" slot-scope="scope">
            <el-select
              v-model="pageParams.coorState"
              placeholder=""
              size="small"
              clearable
              @change="getList"
            >
              <el-option
                v-for="(item, i) in coorStateList"
                :key="i"
                :label="item.codeName"
                :value="item.codeId"
              />
            </el-select>
          </template>
          <span slot-scope="scope" width="300">
            <template>
              <span
                class="coor-state"
                :class="{
                  'state-connected':
                    scope.row.coorState == COOR_STATE_CONNECTED,
                  'state-connecting':
                    scope.row.coorState == COOR_STATE_CONNECTING,
                }"
              >
                {{ scope.row.coorStateDesc }}
              </span>
            </template>
          </span>
        </el-table-column>
      </el-table-column>

      <el-table-column align="center" prop="coor_name" :label="$t('devices.esl.APName')"  sortable="custom">
        <el-table-column align="center" prop="coorName">
          <template slot="header" slot-scope="scope">
            <el-input
              v-model="pageParams.coorName"
              @input="getList"
              size="small"
              placeholder=""
              clearable
            />
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column
        align="center"
        width="120"
        prop="ip_addr"
        :label="$t('devices.esl.IPAddress')"
        sortable="custom"
      >
        <el-table-column align="center" prop="ipAddr">
          <template slot="header" slot-scope="scope">
            <el-input
              v-model="pageParams.ipAddr"
              @input="getList"
              size="small"
              placeholder=""
              clearable
            />
          </template> </el-table-column
      ></el-table-column>
    </el-table>
    <pagination :tableInfo="pageParams" @changePage="changePage" />
  </div>
</template>
<script>
import moment from "moment";
import { debounce } from "lodash";
import { getStoreApList, getCodeList } from "@/libs/api/aps";
import pagination from "@/components/pagination";
import {
  COOR_STATE_CONNECTED,
  COOR_STATE_CONNECTING,
  paginationEnum,
} from "@/libs/enum";
export default {
  components: {
    pagination,
  },
  props: {
    taskTypeList: {
      type: Array,
      require: true,
      default: () => [],
    },
    elsStateList: {
      type: Array,
      require: true,
      default: () => [],
    },
  },
  name: "TransmitByAp",
  data() {
    return {
      moment,
      COOR_STATE_CONNECTED,
      COOR_STATE_CONNECTING,
      paginationEnum,
      pageParams: {
        current: 1,
        size: 10,
        total: 0,

        storeType: "",
        storeName: "",
        ipAddr: "",
        coorName: "",
        coorState: "",
        orders: []
      },
      tableData: [],
      tableLoading: false,
      coorStateList: [],

      apIds: [],
    };
  },
  methods: {
     // 表格排序
    handleSort(column, prop, order){
      console.log('表格排序',column, prop, order)
      let arr = []
      if(column.order){
        let str1= column.order === "ascending" ? "asc" : "desc";
        str1 = str1 + ':'+ column.prop;
        arr.push(str1)
      } else {
        arr = []
      }
      
      this.pageParams.orders = arr;
      this.getList()
    },
    changePage() {
      this.getList();
    },
    getList: debounce(async function () {
      const _this = this;
      const params = {
        storeId: sessionStorage.getItem("storeId"),
        storeType: _this.pageParams.storeType,
        storeName: _this.pageParams.storeName,
        ipAddr: _this.pageParams.ipAddr,
        coorName: _this.pageParams.coorName,
        coorState: _this.pageParams.coorState,

        current: _this.pageParams.current,
        size: _this.pageParams.size,
        orders: _this.pageParams.orders,
      };
      _this.tableLoading = true;
      try {
        const { data } = await getStoreApList(params);
        if (data.state === true) {
          let dataNew = data.data || {};
          _this.tableData = dataNew.records || [];
          _this.pageParams.total = dataNew.total || 0;
          _this.tableLoading = false;
        } else {
          this.$message.error(data.message);
          _this.tableLoading = false;
        }
      } catch (err) {
        const { message } = err;
        _this.$message.error(message);
        _this.tableLoading = false;
      }
    }, 1000),
    async getCodeList() {
      try {
        const { data } = await getCodeList({ cateId: 9 });
        if (data.state === true) {
          this.coorStateList = data.data || [];
        } else {
          this.$message.error(data.message);
        }
      } catch (err) {
        const { message } = err;
        this.$message.error(message);
      }
    },
    handleSelectionChange(data) {
      let apIds = [];
      data.forEach((item) => {
        apIds.push(item.coorId);
      });
      this.apIds = apIds;
    },
  },
  created() {
    this.getList();
    this.getCodeList();
  },
};
</script>
<style lang="scss" scoped>
.communication-records_top {
  margin-bottom: 20px;
  .record-text {
    font-size: 16px;
    color: #999999;
  }
}
.coor-state {
  padding: 3px 18px;
  text-align: center;
  border-radius: 15px;
  display: inline-block;
}
.state-connected {
  background: rgba(217, 250, 232, 1);
}
.state-connecting {
  background: rgba(193, 233, 255, 1);
}

.table-content ::v-deep {
  .el-table
    .el-table__header-wrapper
    .el-table__header
    .is-group
    tr:nth-of-type(2)
    th:nth-of-type(1)
    .cell {
    display: none;
  }
}
</style>